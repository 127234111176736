@use "setting" as *;

:root {
  --vw: 1vw;
}

/* ======================================================================
 top
====================================================================== */

body {
  @media only screen and (max-width: 767px) {
    background: url(../img/page_bg.jpg) repeat-y left top / 100%;
  }
}

.l_contents {
  background: url(../img/top/bg.jpg) no-repeat left top / 100%;
  background-position-y: pv3(800);
  @media only screen and (max-width: 767px) {
    background: url(../img/top/bg.jpg) no-repeat left top / 100%;
    background-position-y: 170vw;
  }
}


.l_kv {
  .slide {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: pv3(800);
    @media only screen and (max-width: 767px) {
      height: 170vw;
    }
    .slide_1,
    .slide_2 {
      position: absolute;
      top: 0;
      left: 0;
      width: pv3(800);
      @media only screen and (max-width: 767px) {
        overflow: hidden;
        width: 100%;
        height: 66.7vw;
      }
      img {
        @media only screen and (max-width: 767px) {
          width: 100%;
          height: 66.7vw;
          object-fit: cover;
        }
      }
    }
    .slide_2 {
      right: 0;
      left: auto;
      @media only screen and (max-width: 767px) {
        top: auto;
        bottom: 0;
      }
    }
  }
  .kv {
    @media only screen and (max-width: 767px) {
      top: 66.7vw;
    }
  }
}

.bnr_link {
  display: block;
  opacity: 1;
  transition: opacity .3s;
  &:hover {
    opacity: .7;
  }
}

.sec {
  &._1 {
    padding: pv3(450) 0 pv3(123);
    @media only screen and (max-width: 767px) {
      padding: 170vw pv(20) pv(80);
    }
    .main_copy {
      position: relative;
      z-index: 2;
      margin-right: auto;
      margin-left: auto;
      width: fit-content;
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: pv3(20);
        border-radius: pv3(5);
        background: linear-gradient(transparent 0%, #f00 0%);
        content: "";
        @media only screen and (max-width: 767px) {
          height: pv(14);
          border-radius: pv(5);
        }
      }
    }
  }
  &._2 {
    padding: 0 0 pv3(221);
    @media only screen and (max-width: 767px) {
      padding: 0 pv(20) pv(100);
    }
    .flex_box {
      display: flex;
      justify-content: center;
      gap: pv3(20);
      margin: pv3(142) auto 0;
      max-width: pv3(1510);
      @media only screen and (max-width: 767px) {
        flex-direction: column;
        gap: pv(30);
        max-width: 100%;
      }
      .item {
        position: relative;
        padding: pv3(230) 0 pv3(30);
        width: calc(33.33% - pv3(40) / 3);
        border-radius: pv3(20);
        background-color: $white;
        @media only screen and (max-width: 767px) {
          padding: pv(120) 0 pv(20);
          width: 100%;
          border-radius: pv(20);
        }
        .img1,
        .img2,
        .img3 {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
        .img1 {
          top: pv3(-61.5);
          width: pv3(294);
          @media only screen and (max-width: 767px) {
            top: pv(-20);
            width: pv(140);
          }
        }
        .img2 {
          top: pv3(-47.5);
          width: pv3(386);
          @media only screen and (max-width: 767px) {
            top: pv(-7);
            width: pv(190);
          }
        }
        .img3 {
          top: pv3(-61.5);
          width: pv3(341);
          @media only screen and (max-width: 767px) {
            top: pv(-16);
            width: pv(160);
          }
        }
        .label {
          margin: pv3(23) auto pv3(20);
          width: pv3(242);
          border-radius: pv3(10);
          background-color: $color_1;
          color: $color_2;
          font-size: pv3(25);
          line-height: pv3(57);
          @media only screen and (max-width: 767px) {
            margin: pv(15) auto;
            width: pv(200);
            border-radius: pv(10);
            font-size: pv(16);
            line-height: pv(28);
          }
        }
      }
    }
    .c_yt_box {
      margin: pv3(97) auto 0;
      @media only screen and (max-width: 767px) {
        margin: pv(60) auto 0;
      }
    }
  }
  &._3 {
    position: relative;
    padding: pv3(100) 0 pv3(206);
    background-color: #eaf6fd;
    @media only screen and (max-width: 767px) {
      padding: pv(80) pv(20);
    }
    .box_1 {
      display: flex;
      gap: pv3(30);
      margin: 0 auto;
      max-width: pv3(1509);
      @media only screen and (max-width: 767px) {
        flex-direction: column;
        gap: pv(20);
        max-width: 100%;
      }
      .item {
        width: calc(50% - pv3(30));
        border-radius: pv3(30);
        @media only screen and (max-width: 767px) {
          width: 100%;
          border-radius: pv(30);
        }
        .link {
          display: block;
          width: 100%;
          height: 100%;
        }
        .ttl {
          padding: pv3(30) 0;
          border-radius: pv3(30) pv3(30) 0 0;
          background-color: $color_1;
          @media only screen and (max-width: 767px) {
            padding: pv(20) 0;
            border-radius: pv(20) pv(20) 0 0;
          }
          span {
            display: block;
          }
        }
      }
    }
    .box_2 {
      display: flex;
      gap: pv3(30);
      margin: pv3(38) auto 0;
      max-width: pv3(1509);
      @media only screen and (max-width: 767px) {
        flex-wrap: wrap;
        justify-content: space-between;
        gap: pv(20) pv(10);
        margin: pv(20) auto 0;
        max-width: 100%;
      }
      .item {
        width: calc(20% - pv3(80) / 5);
        border-radius: pv3(20);
        @media only screen and (max-width: 767px) {
          width: calc(50% - pv(5));
          border-radius: pv(20);
        }
        .link {
          display: block;
          width: 100%;
          height: 100%;
        }
        .ttl {
          padding: pv3(25) 0 pv3(18);
          border-radius: pv3(20) pv3(20) 0 0;
          background-color: #f7931e;
          @media only screen and (max-width: 767px) {
            padding: pv(20) 0;
            border-radius: pv(20) pv(20) 0 0;
          }
          span {
            display: block;
          }
        }
      }
    }
  }
  &._4 {
    position: relative;
    padding: pv3(105) 0 pv3(206);
    background-color: #eaf6fd;
    @media only screen and (max-width: 767px) {
      padding: pv(80) pv(20);
    }
    .box_1 {
      display: flex;
      gap: pv3(30);
      margin: 0 auto pv3(145);
      max-width: pv3(1509);
      @media only screen and (max-width: 767px) {
        flex-direction: column;
        gap: pv(20);
        margin: 0 auto pv(60);
        max-width: 100%;
      }
      .item {
        width: calc(33.333% - pv3(30));
        @media only screen and (max-width: 767px) {
          width: 100%;
        }
        .ttl {
          padding: pv3(25) 0;
          border-radius: pv3(30) pv3(30) 0 0;
          background-color: $color_2;
          @media only screen and (max-width: 767px) {
            padding: pv(20) 0;
            border-radius: pv(30) pv(30) 0 0;
          }
          span {
            display: block;
            margin-top: pv3(5);
            @media only screen and (max-width: 767px) {
              margin-top: pv(5);
            }
          }
        }
        iframe {
          width: 100%;
          height: pv3(406);
          @media only screen and (max-width: 767px) {
            height: pv(280);
          }
        }
      }
    }
    .c_ttl_1 {
      &._2 {
        margin-bottom: pv3(23);
        @media only screen and (max-width: 767px) {
          margin-bottom: pv(20);
        }
      }
    }
    .box_2 {
      display: flex;
      gap: pv3(30);
      margin: pv3(69) auto pv3(48);
      max-width: pv3(1509);
      @media only screen and (max-width: 767px) {
        flex-direction: column;
        gap: pv(20);
        margin: pv(30) auto pv(40);
        max-width: 100%;
      }
      .item {
        padding: pv3(32) 0 pv3(40);
        width: calc(33.333% - pv3(30));
        border-radius: pv3(10);
        background-color: $white;
        @media only screen and (max-width: 767px) {
          padding: pv(20) 0;
          width: 100%;
          border-radius: pv(10);
        }
        .ttl {
          span {
            display: block;
            margin-top: pv3(5);
          }
        }
        img {
          display: block;
          margin: pv3(14) auto pv3(24);
          width: pv3(312);
          @media only screen and (max-width: 767px) {
            margin: pv(10) auto pv(15);
            width: pv(300);
          }
        }
        .flex_1 {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: pv3(19);
          margin-bottom: pv3(21);
          @media only screen and (max-width: 767px) {
            gap: pv(10);
            margin-bottom: pv(15);
          }
        }
        .flex_2 {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: pv3(30);
          @media only screen and (max-width: 767px) {
            flex-direction: column;
            gap: pv(10);
          }
          .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: pv3(138);
            height: pv3(48);
            border: 1px solid $color_1;
            border-radius: pv3(3);
            background-color: $white;
            transition: background-color .3s, color .3s;
            @media only screen and (max-width: 767px) {
              width: pv(250);
              height: pv(40);
            }
            &:hover {
              @media only screen and (min-width: 768px) {
                background-color: $color_1;
                color: $white;
              }
            }
          }
        }
      }
    }
    .c_btn_1 {
      &:hover {
        background-color: transparent;
        color: #00a0e9;
      }
    }
  }
  &._5 {
    position: relative;
    padding: pv3(177) 0 pv3(137);
    background-color: #ffe100;
    @media only screen and (max-width: 767px) {
      padding: pv(80) pv(20);
    }
    .c_ttl_2 {
      top: pv3(-20);
    }
    .flex_box {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      max-width: pv3(1309);
      @media only screen and (max-width: 767px) {
        flex-direction: column;
        max-width: 100%;
      }
      .img {
        display: block;
        margin-bottom: pv3(45);
        width: pv3(532);
        @media only screen and (max-width: 767px) {
          margin: 0 auto pv(20);
          width: pv(200);
        }
      }
      .list {
        margin-top: pv3(15);
        padding-left: pv3(32);
        border-left: pv3(4) solid #534741;
        @media only screen and (max-width: 767px) {
          margin: pv(10) auto pv(40);
          padding-left: pv(20);
          border-left: pv(3) solid #534741;
        }
      }
      .ttl {
        display: flex;
        align-items: center;
        justify-content: center;
        width: pv3(403);
        height: pv3(89);
        border: 1px solid $color_1;
        border-radius: pv3(10);
        @media only screen and (max-width: 767px) {
          padding: pv(15) 0;
          width: 100%;
          height: auto;
          border-radius: pv(10);
        }
      }
      .c_btn_2 {
        margin: pv3(75) 0 0;
        @media only screen and (max-width: 767px) {
          margin: pv(20) 0 0;
        }
      }
    }
  }
  &._6 {
    position: relative;
    padding: pv3(82) 0 pv3(156);
    background-color: #eaf6fd;
    @media only screen and (max-width: 767px) {
      padding: pv(80) pv(20);
    }
    .c_yt_box {
      margin: 0 auto;
      & + .c_yt_box {
        margin-top: pv3(40);
        @media only screen and (max-width: 767px) {
          margin-top: pv(20);
        }
      }
    }
  }
  &._7 {
    position: relative;
    padding: pv3(154) 0 pv3(169);
    @media only screen and (max-width: 767px) {
      padding: pv(80) pv(20);
    }
    .box {
      display: flex;
      flex-wrap: wrap;
      gap: pv3(20);
      margin: 0 auto;
      max-width: pv3(1509);
      @media only screen and (max-width: 767px) {
        flex-direction: column;
        gap: pv(20);
        max-width: 100%;
      }
      .item {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        width: calc(33.333% - pv3(40) / 3);
        height: pv3(360);
        border-radius: pv3(20);
        background-color: #eaf6fd;
        @media only screen and (max-width: 767px) {
          padding: pv(20) 0;
          width: 100%;
          height: auto;
          border-radius: pv(20);
        }
        &:nth-of-type(even) {
          background-color: #fdffdf;
        }
        .ttl {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: pv3(32);
          width: 100%;
          height: pv3(180);
          @media only screen and (max-width: 767px) {
            margin-bottom: pv(15);
            height: auto;
          }
          &._2 {
            height: pv3(129);
            @media only screen and (max-width: 767px) {
              height: auto;
            }
          }
          &::after {
            position: absolute;
            bottom: 0;
            left: 50%;
            display: block;
            width: pv3(390);
            height: 1px;
            border-bottom: 2px solid #00a0e9;
            content: "";
            transform: translateX(-50%);
            @media only screen and (max-width: 767px) {
              width: 80%;
            }
          }
        }
        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: pv3(20) auto 0;
          width: pv3(110);
          height: pv3(38);
          border: 1px solid #00a0e9;
          border-radius: pv3(3);
          background-color: transparent;
          color: $color_1;
          transition: background-color .3s, color .3s;
          @media only screen and (max-width: 767px) {
            margin: pv(15) auto 0;
            width: pv(200);
            height: pv(40);
            border-radius: pv(10);
          }
          &:hover {
            @media only screen and (min-width: 768px) {
              background-color: $color_1;
              color: $white;
            }
          }
        }
      }
    }
    .bnr_wrap {
      display: flex;
      justify-content: center;
      gap: pv3(40);
      margin: pv3(167) auto 0;
      @media only screen and (max-width: 767px) {
        flex-direction: column;
        gap: pv(10);
        margin: pv(40) auto 0;
      }
      .bnr {
        display: block;
        width: pv3(518);
        height: pv3(170);
        @media only screen and (max-width: 767px) {
          width: 100%;
          height: auto;
        }
        img {
          max-height: 100%;
        }
      }
    }
  }
  &._8 {
    position: relative;
    padding: pv3(78) 0 pv3(125.7);
    background-color: #eaf6fd;
    @media only screen and (max-width: 767px) {
      padding: pv(80) pv(20);
    }
    .ttl_1 {
      position: relative;
      line-height: 1;
      .u_txt_12 {
        position: relative;
        top: pv3(-10);
        line-height: 1;
      }
    }
    .more {
      position: absolute;
      top: pv3(119);
      left: calc(50% + pv3(517));
      display: flex;
      align-items: center;
      justify-content: center;
      width: pv3(131);
      height: pv3(40);
      border: 1px solid #231815;
      background-color: transparent;
      color: #231815;
      font-size: pv3(18.32);
      line-height: 1;
      transition: background-color .3s, color .3s;
      @media only screen and (max-width: 767px) {
        top: pv(100);
        right: pv(20);
        left: auto;
        width: pv(100);
        height: pv(20);
        font-size: pv(12);
      }
      &:hover {
        @media only screen and (min-width: 768px) {
          background-color: #231815;
          color: $white;
        }
      }
    }
    .c_blog_list {
      margin-top: pv3(56);
      @media only screen and (max-width: 767px) {
        margin-top: pv(20);
      }
    }
  }
}
